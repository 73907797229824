/* Footer */
.footer{
    padding: 2% 0;
    margin: 2% 0 0;
    width: 100%;
    background-color: #FA9500;
    color: #020F17;
    display: block;
}
  
.social-icon{
    color: #020F17;
    margin: 1% 1%;
}
  
.social-icon:hover, .social-icon:active{
    color: #dc3545;
}
  
a.footer-link{
    color: #020F17;
    text-decoration: none;
}
  
a.footer-link:hover, a.footer:active{
    color: #dc3545;
    text-decoration: underline;
}
  
.contact-icon {
    color: #020F17;
    padding-right: 3%;
}
  
.footer-logo {
    width: 8vw;
    max-width: 300px;
    min-width: 100px;
}
  