.character-profile-main {
    button {
        color: #fff;
        text-decoration: none;

        & :hover, :active, :focus {
            color: #FEC200  !important;
            text-decoration: underline;
        }

        .active a {
            color: #FA9500;
        }

    }

    nav-link {
        color: #fff;

        & :hover, :active, :focus {
            color: #FEC200  !important;
            text-decoration: underline;
        }

        .active a {
            color: #FA9500;
        }
    }

    .icon {
        color: #fff;

        & :hover, :active, :focus {
            color: #FEC200  !important;
            text-decoration: underline;
        }

        .active a {
            color: #FA9500;
        }
    }
}
.character-avatar-container {
    width: 100%;
    height: 100%;
    max-height: 30rem;
    object-fit: contain;      
    border-radius: 0;   
    
    img {
        width:100%;
        min-width: 20rem;
        object-fit: contain;
        border-radius: 0; 
    }
}

.attribute-icon {
    cursor: pointer;

    & :hover, :active, :focus {
        color: #FEC200  !important;
        text-decoration: underline;
    }

}