
.featured-container {
  min-width: 30%;
  height: 300px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  margin: 0 7.5px 15px;
  overflow: hidden;
  border-color: #020F17;

  &:hover {
    cursor: pointer;

    & .background-image {
      transform: scale(1.1);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

    & .featured-body-container {
      opacity: 0.9;
    }
  }

  &.large {
    height: 380px;
  }

  &:first-child {
    margin-right: 7.5px;
  }

  &:last-child {
    margin-left: 7.5px;
  }

  .background-image {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-size: cover;
    background-position: center;
    filter: blur(2px);
  }

  .featured-body-container {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0px;
    background-color: #020F17;
    opacity: 0.7;
    position: absolute;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);

    h2 {
      font-weight: bold;
      margin: 0 6px 0;
      font-size: 24px;
      color: #045184; 
    }

    p {
      font-weight: lighter;
      font-size: 20px;
      color: #4a4a4a;
    }
  }
}