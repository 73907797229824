.game-profile-main {
    button {
        color: #fff;
        text-decoration: none;

        & :hover, :active, :focus {
            color: #FEC200  !important;
            text-decoration: underline;
        }

        .active a {
            color: #FA9500;
        }

    }

    nav-link {
        color: #fff;

        & :hover, :active, :focus {
            color: #FEC200  !important;
            text-decoration: underline;
        }

        .active a {
            color: #FA9500;
        }
    }

    .icon {
        color: #fff;

        & :hover, :active, :focus {
            color: #FEC200  !important;
            text-decoration: underline;
        }

        .active a {
            color: #FA9500;
        }
    }
}

.character-avatar {
    width: 100%;
    max-height: 200px;
    display: block;
    object-fit: contain; 
}

.character-icon-container {
    width: 18rem;
    height: 18rem;
    align-items: center;
    justify-content: center;
    border: 0px solid black;
    margin: 0 10px 15px;
    overflow: hidden;
    border-radius: 100%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);

    img {
        width: 18rem;
    }

}

.attribute-icon {
    cursor: pointer;

    & :hover, :active, :focus {
        color: #FEC200  !important;
        text-decoration: underline;
    }

}

.editor {
    min-height: 10vh;
}