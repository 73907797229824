.game-browse-card {
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #333333;
    color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
    transition: box-shadow .25s; 
    min-height: 10vh;
    
    a {
        color: #FA9500;
        text-decoration: none;

        & :hover, :active {
            color: #dc3545;
            text-decoration: underline;
        }
    }

    .img-card {
        overflow: hidden;
        width: 100%;
        height:6rem;
        border-top-left-radius:2px;
        border-top-right-radius:2px;
        padding-bottom: 1rem;
        padding-top: 0;
        display:block;
        object-fit: cover; 
        transition: all .25s ease;
    }
    
    .card-description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        line-clamp: 3; 
        -webkit-box-orient: vertical;
    }
    
    .card-subtext {
        font-size: small;
    }
    
    .card-category {
        z-index: 2;
        position: relative;
    }
    
}

