@import '~bootstrap/scss/bootstrap';

/* Everything */
* {
  box-sizing: border-box;
}

/* Body style */
body, .wrapper {
  min-height: -webkit-fill-available;
  min-height: 100vh;
  font-family: 'Nunito', sans-serif;
  text-align: center;
}

.flex-fill {
  flex:1 1 auto;
}

:root {
  --bs-blue: #045184;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #FA9500;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #045184;
  --bs-secondary: #737373;
  --bs-success: #198754;
  --bs-info: #5D9AD0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #045184;
  --bs-primary-rgb: 4, 81, 132;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 	93, 154, 208;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 4, 81, 132;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #ffffff;
  --bs-body-bg: #020F17;
}

/* HTML elements */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Nunito', sans-serif;
}

a.active, a.hover{
  color:#FA9500;
}

input[type=checkbox]:checked {
  background-color: #FA9500;
}

input[type=radio]:checked {
  background-color: #FA9500;
}

/*Buttons*/
.btn-primary{
  background-color:#FA9500;
  color: #020F17;
  border-width: 0;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus{
  background-color: #FEC200;
  color: #020F17;
}

.btn-outline-dark, .btn-outline-light:hover, .btn-outline-light:active{
  color: #FEC200;
  border-color: #737373;
}

.btn-outline-dark:hover, .btn-outline-dark:active, .btn-outline-dark:focus{
  background-color: #FA9500;
  border-color: #FA9500;
  color: #020F17;
}

/* Icons */
.medium-icon{
  color: #FEC200; 
  padding-bottom: 10px
}

/*
.medium-icon:hover, .feature-icon:hover{
  color: #FA9500;; 
}
*/

/* Multi-page styles */
.informational-body {
  text-align: center;
}

.primary-color{
  color: #FEC200; 
}

.secondary-color {
  color: #FA9500;
}

.featured-video {
  width: 32vw;
  height: 18vw;
  min-width: 220px;
  min-height: 120px;
}

.subtle-link {
  text-decoration: none;
  color: FA9500;
  cursor: pointer;

  & :hover, :active {
    color: #FEC200;
  }
}

/* Media queries */
@media (min-width: 900px) {
  body{
      text-align: left;
  }
}