/*Navbar*/
.logo {
    width: 10vw;
    max-width: 400px;
    min-width: 120px;
}

.navbar-link{
    padding: 0 10px;
    font-weight: bold;
    color: #ffffff;
    margin:5px 0px;

    & :hover, :active, :focus {
        color: #FEC200  !important; 
        background-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    a.active, .visited, .focus, .active, .dropdown-active { 
        color:#FA9500 !important; 
        font-weight: bold;
    }
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    font-weight: bold;
    color: #ffffff;
    font-size: 1.4rem;
    
    &:hover, :active {
        color: #FEC200  !important; 
        background-color: transparent;
    }
  }